<template>
  <div class="wrap">
    <div class="filter">
      <el-input
        style="width: 250px"
        placeholder="请输入职位标题搜索"
        v-model="search_str"
        class="input-with-select"
      >
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>

      <el-table-column label="公司logo">
        <template slot-scope="scope">
          <el-image
            style="width: 50px"
            :src="scope.row.logo"
            :preview-src-list="[scope.row.license]"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >添加悬赏</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="150" title="编辑" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="职位悬赏设置" name="essentialInformation">
          <el-form :model="formamend">
            <el-form-item label="职位名称" label-width="120">
              <el-input
                v-model="formamend.jd_title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名称" label-width="120">
              <el-input
                v-model="formamend.license_company_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否是否参与悬赏">
              <el-switch
                active-color="#58362A"
                v-model="formamend.jd_isreward"
                active-text="是"
                inactive-text="否"
              ></el-switch>
            </el-form-item>

            <el-form-item label="赏金" label-width="120">
              <el-input
                v-model="formamend.jd_money"
                autocomplete="off"
              ></el-input
              >元
            </el-form-item>

            <el-form-item label="规则" label-width="120">
              <el-input
                v-model="formamend.jd_rule"
                autocomplete="off"
                rows="10"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btn" @click="amend">确 定</el-button>
      </div>
    </el-dialog>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  search_agent,
  get_agent,
  jd_agent_add,
  jd_search,
} from "@/api/agent/index.js";

import { showCity } from "@/api/resume";
import axios from "axios";
export default {
  data() {
    return {
      //总页码
      pageTotal: null,
      pageNow: 1,

      search_str: "",
      search_sel: [
        { label: "用户", value: "username" },
        { label: "公司", value: "license_company_name" },
      ],
      select_index: "",
      // 表单数据处理
      tableData_cell: [
        { title: "职位标题", value: "jd_title" },
        { title: "公司名称", value: "license_company_name" },
        { title: "是否参与悬赏", value: "jd_isreward" },
        { title: "公众号推广小程序码", value: "xcx_code" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        jd_title: "",
        license_company_name: [],
        jd_money: "",
        jd_rule: "",
        jd_isreward: "",
      },
      place_data: [],
      industry_name: "",
      jd_id: "",
      form: {},
      activeName: "essentialInformation",
    };
  },
  mounted() {
    axios.get("/static/industry.json").then((res) => {
      this.indry = res;
    });
    showCity({}).then((res) => {
      console.log(res);
      this.city = res.data;
    });
  },
  methods: {
    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {
      let obj = {
        company_user: this.search_str,
        vip_end_time: this.getTime(this.form.date),
        is_seed: this.form.is_seed == true ? 1 : 0,
      };

      amend_date(obj).then((res) => {
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      let search_data = {
        jd_title: this.search_str,
        page: this.pageNow,
      };
      jd_search({ search_data }).then((res) => {
        if (!res.code) {
          if (res.data.length) {
            res.data.map((item) => {
              item.jd_isreward = item.jd_isreward == 1 ? "是" : "否";
            });
          }

          this.pageTotal = res.all_count;
          this.tableData = res.data;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },

    redact(e) {
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.jd_id = e.jd_id;
      this.formamend = {
        jd_title: e.jd_title,
        license_company_name: e.license_company_name,
        jd_isreward: e.jd_isreward == "是" ? true : false,

        jd_money: e.jd_money,
        jd_rule: e.jd_rule,
      };
    },

    amend() {
      console.log("添加职位悬赏");

      if (this.activeName == "essentialInformation") {
        let update_data = JSON.stringify(this.formamend);

        // if(this.formamend.jd_isreward)
        // {
        //   this.formamend.jd_isreward=1
        // }else
        // {
        //   this.formamend.jd_isreward=0
        // }

        let obj = {
          jd_id: this.jd_id,
          jd_isreward: this.formamend.jd_isreward ? 1 : 0,
          jd_money: this.formamend.jd_money,
          jd_rule: this.formamend.jd_rule,
          jd_isstatus: 1,
        };
        jd_agent_add(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "MemberExpiration") {
        this.onSubmit();
        return false;
      } else {
      }
    },

    pageNowHandler(page) {
      if (this.pageNow != page) {
        this.loading = true;

        this.pageNow = page;

        this.search(1);
      }
    },
  },
  created() {
    this.pageNow = 1;
    this.search(1);
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}
</style>
